import React from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import config from "./config";
import ChangePassword from "./ChangePassword";
import Home from "./Home";
import Login from "./Login";
import Logout from "./Logout";
import Profile from "./Profile";
import 'bootstrap/dist/css/bootstrap.min.css';

import "./App.scss";
import { useEffect } from "react";

const oktaAuth = new OktaAuth(config.oidc);

const App = () => {
  const history = useHistory();

  const onAuthRequired = () => {
    history.push("/login");
  };

  const onAuthResume = async () => {
    history.push("/login");
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "", window.location.origin));
  };

  useEffect(() => {
    localStorage.removeItem("okta-token-storage");
    localStorage.removeItem("okta-cache-storage");
  }, []);

  return (
    <div className="app-container">
      <div className="main-container">
        <Security
          oktaAuth={oktaAuth}
          onAuthRequired={onAuthRequired}
          restoreOriginalUri={restoreOriginalUri}
        >
          <SecureRoute path="/" exact component={Home} />
          <SecureRoute
            path="/changepassword"
            exact
            component={ChangePassword}
          />
          <SecureRoute path="/profile" exact component={Profile} />
          <Route path="/login" exact render={() => <Login config={config.widget} />} />
          <Route path="/login/vendor" exact>
            <Redirect to="/login?vendor=1" />
          </Route>
          <Route path="/logout" exact component={Logout} />
          <Route
            path="/login/callback"
            render={(props) => (
              <LoginCallback {...props} onAuthResume={onAuthResume} />
            )}
          />
          <Route path="/reset/callback" exact 
            render={() => <Login config={config.widget} />} />
        </Security>
      </div>
      <div className="footer-container">
        <div>
          <span>&copy; 2023 Corpay</span>
          <span>&nbsp;|&nbsp;</span>
          <span>
            <a
              href="https://payments.corpay.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default App;
